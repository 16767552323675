<template>
  <div class="products">
    <subtitle>
      <div slot="above-title">优势产品</div>
      <div slot="under-title">标杆产品，科技赋能，全方位满足市场需求</div>
    </subtitle>
    <div class="products_content">
      <tab-control-left>
        <!-- 艾灸机器人 -->
        <!-- <tab-control-panel label="艾灸机器人" name="艾灸机器人">
          <img src="~assets/img/home/aijiu-robot.png" alt="" />
          <div class="content_title">艾灸机器人</div>
          <div class="content_text">
            ◆ 快速AI寻穴，335个维度儿童成人智能识别
          </div>
          <div class="content_text">◆ 安全、高效、智能、易用</div>
          <div class="content_text">
            ◆ 智能控温，温和灸、雀啄灸、回旋灸等灸法 1:1 精准操作
          </div>
        </tab-control-panel> -->
        <!-- 脉象仪 -->
        <tab-control-panel label="智能血压脉象仪" name="智能血压脉象仪">
          <div class="content_maixiang">
            <div class="maixiang_part">
              <div
                v-for="(item, index) in maixiangListLeft"
                class="maixiang_list_item"
                :key="index"
              >
                <img :src="item.iconUrl" />
                <div>
                  <div class="item_title">{{ item.title }}</div>
                  <div class="item_text">{{ item.text }}</div>
                </div>
              </div>
            </div>
            <img
              src="~assets/img/home/maixiangyi.png"
              class="maixiang_part"
              style="object-fit: none"
            />
            <div class="maixiang_part">
              <div
                v-for="(item, index) in maixiangListRight"
                class="maixiang_list_item"
                :key="index"
              >
                <img :src="item.iconUrl" />
                <div>
                  <div class="item_title">{{ item.title }}</div>
                  <div class="item_text">{{ item.text }}</div>
                </div>
              </div>
            </div>
          </div>
        </tab-control-panel>
        <!-- 经络仪 -->
        <tab-control-panel label="便携式经络检测仪" name="便携式经络检测仪">
          <img src="~assets/img/home/jingluoyi.png" style="object-fit: none" />
          <div class="content_title">便携式经络检测仪</div>
          <div class="content_text">◆ 采用多项自主研发的专利技术</div>
          <div class="content_text">
            ◆ 迅速采集人体十二经络原穴的生物电阻信号
          </div>
          <div class="content_text">
            ◆ 提高了检测的精准度、稳定性、有效性及抗干扰性
          </div>
        </tab-control-panel>
      </tab-control-left>
    </div>
  </div>
</template>

<script>
import Subtitle from 'components/content/subtitle/Subtitle'
import TabControlLeft from 'components/content/tabControl/tabControlLeft/TabControlLeft'
import TabControlPanel from 'components/content/tabControl/TabControlPanel'

export default {
  name: 'Products',
  data() {
    return {
      maixiangListLeft: [
        {
          iconUrl: require('../../../assets/img/home/icon/icon5.png'),
          title: '高精度传感器',
          text: '真实还原心脏跳动血流传播的压力波形，测量精度重复性优于普通血压仪'
        },
        {
          iconUrl: require('../../../assets/img/home/icon/icon6.png'),
          title: '精准血压测量',
          text: '匀速减压过程采用实时闭环反馈系统，准确获取脉搏波形'
        },
        {
          iconUrl: require('../../../assets/img/home/icon/icon7.png'),
          title: '智能加压技术',
          text: '采用智能传感算法，根据测量者血压高低自动调节加压压力'
        },
        {
          iconUrl: require('../../../assets/img/home/icon/icon8.png'),
          title: '红外测量温度',
          text: '非接触式测量温度，一键获取人体表温度'
        }
      ],
      maixiangListRight: [
        {
          iconUrl: require('../../../assets/img/home/icon/icon1.png'),
          title: '脉搏波形展示',
          text: '非实时显示测量脉搏波形，直观了解血液在人体的传播情况'
        },
        {
          iconUrl: require('../../../assets/img/home/icon/icon2.png'),
          title: '脉象云端分析',
          text: '人在不同的健康状态下会产生不同的脉象（脉搏波），云端计算协助解析健康信息'
        },
        {
          iconUrl: require('../../../assets/img/home/icon/icon3.png'),
          title: '智能测量进化',
          text: '用户长期使用可以获得测量正确性、预见性的提高，获得更精准的健康指导'
        },
        {
          iconUrl: require('../../../assets/img/home/icon/icon4.png'),
          title: '蓝牙连接手机',
          text: '通过蓝牙连接手机，进行持续稳定传输'
        }
      ]
    }
  },
  components: {
    Subtitle,
    TabControlLeft,
    TabControlPanel
  }
}
</script>

<style scoped>
.products {
  padding: 50px 0;
}

.products_content {
  height: 550px;
  margin-top: 40px;
}

.content_title {
  color: #a40101;
  font-size: 16px;
  font-weight: bold;
}
.content_text {
  color: #7e7e7e;
  font-size: 16px;
  margin-top: 10px;
}

.content_maixiang {
  display: flex;
  justify-content: center;
}
.maixiang_part {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.maixiang_list_item {
  display: flex;
  align-items: flex-start;
  margin: 20px 0;
  text-align: left;
}
.maixiang_list_item img {
  object-fit: none;
  margin-right: 10px;
}
.maixiang_list_item .item_title {
  font-size: 18px;
  font-weight: bold;
  color: #4c4c4c;
}
.maixiang_list_item .item_text {
  font-size: 16px;
  color: #7e7e7e;
  line-height: 24px;
}
</style>
