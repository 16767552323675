<template>
  <div class="card" :class="{ active: isActive }">
    <div class="img">
      <slot name="img"></slot>
    </div>
    <div class="title">
      <slot name="title"></slot>
    </div>
    <div class="content">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Card',
  props: {
    isActive: false
  }
}
</script>

<style scoped>
.card {
  width: 372px;
  height: 414px;
  padding: 10px;
  margin: 0 20px;
  border: 2px solid #951e23;
  color: #3c3c3c;
}

.active {
  color: #fff;
  background-color: #951e23;
  border: none;
}

.title {
  margin: 20px 0;
  color: #951e23;
  font-size: 20px;
  font-weight: bold;
}

.active .title {
  color: #fff;
}

.content {
  font-size: 18px;
  line-height: 30px;
  text-align: left;
  padding: 0 10px;
}
</style>
