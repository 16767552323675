<template>
  <div class="join">
    <subtitle>
      <div slot="above-title">加盟流程</div>
    </subtitle>
    <div style="width: 1100px; margin: 0 auto">
      <img
        class="content_img"
        src="~assets/img/home/join-step.png"
        width="100%"
        alt=""
      />
    </div>
  </div>
</template>

<script>
import Subtitle from 'components/content/subtitle/Subtitle'

export default {
  name: 'Join',
  components: {
    Subtitle
  }
}
</script>

<style scoped>
.join {
  padding: 50px 0;
}

.content_img {
  margin-top: 60px;
}
</style>
