<template>
  <div class="tab_control_left">
    <div class="tabs_title">
      <div
        class="title_list_item"
        v-for="(item, index) in navList"
        :class="{ item_active: item.name === current }"
        @click="handleChanged(index)"
        :key="index"
      >
        <div class="title_list_item_text">
          {{ item.label }}
        </div>
      </div>
    </div>
    <div class="tabs-content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TabControlLeft',
  data() {
    return {
      current: 0,
      navList: []
    }
  },
  props: {},
  watch: {
    current(val) {
      // 当前tab发生变化时，更新panel的显示状态
      this.updateStatus()
    }
  },
  methods: {
    // 获取panel子组件
    getTabs() {
      return this.$children.filter((item) => {
        return item.$options.name == 'TabControlPanel'
      })
    },

    // 显示对应的panel组件内容，并隐藏未被选中的panel
    updateStatus() {
      this.getTabs().forEach((panel) => {
        panel.visible = panel.name === this.current
      })
    },

    // 更新tabs
    updateNav() {
      this.navList = []

      this.getTabs().forEach((panel, index) => {
        this.navList.push({
          label: panel.label,
          name: panel.name || index
        })
        // 如果panel没有定义name属性，那么使用index作为panel标识
        if (!panel.name) {
          panel.name = index
        }
        // 设置第一个panel为当前活动panel
        if (index === 0) {
          if (!this.current) {
            this.current = panel.name || index
          }
        }
        this.updateStatus()
      })
    },

    // 点击标签页标题时触发
    handleChanged(index) {
      // 修改当前选中tab
      this.current = this.navList[index].name
    }
  }
}
</script>

<style scoped>
.tab_control_left {
  display: flex;
  width: 1200px;
  height: 100%;
  margin: 0 auto;
}

.tabs_title {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.title_list_item {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ededed;
  border: 1px solid #e1dddd;
  color: #7e7e7e;
  cursor: pointer;
}

.title_list_item_text {
  font-size: 20px;
}

.item_active {
  background-color: #951e23;
  border: none;
  color: #fff;
}

.tabs-content {
  flex: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fafafa;
  border: 1px solid #e1dddd;
  border-left: none;
  padding: 30px;
}
</style>
