<template>
  <div class="home">
    <div class="home_top">
      <div class="home_top_img"></div>
      <div class="home_top_bar"></div>
    </div>
    <company-profile />
    <reason />
    <!-- <advantage /> -->
    <products />
    <brand />
    <join />
  </div>
</template>

<script>
import CompanyProfile from '../../components/common/companyProfile.vue'
import Reason from './subpage/reason'
// import Advantage from './subpage/advantage'
import Products from './subpage/products'
import Brand from './subpage/brand'
import Join from './subpage/join'

export default {
  name: 'home',
  components: {
    CompanyProfile,
    Reason,
    // Advantage,
    Products,
    Brand,
    Join
  }
}
</script>

<style scoped>
.home {
  text-align: center;
  width: 100%;
  min-width: 1220px;
}

.home_top {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.home_top_img {
  background: url('~assets/img/home/banner.png') top center no-repeat;
  height: 665px;
  min-width: 1220px;
}

.home_top_bar {
  background: url('~assets/img/home/bar.png') top center no-repeat;
  height: 79px;
  min-width: 1220px;
}
</style>
