<template>
  <div class="reason">
    <subtitle>
      <div slot="above-title">为什么选择大富智慧健康</div>
    </subtitle>
    <div class="reason_content">
      <card>
        <img
          slot="img"
          src="~assets/img/home/card1.png"
          width="100%"
          height="100%"
          alt=""
        />
        <div slot="title">大趋势</div>
        <div slot="content">2020年-2025年，健康产业复合增长率提升50%</div>
      </card>
      <card>
        <img
          slot="img"
          src="~assets/img/home/card2.png"
          width="100%"
          height="100%"
          alt=""
        />
        <div slot="title">大政策</div>
        <div slot="content">
          十三五规划，2035年前，政府将投资600亿元，助力健康事业发展。
        </div>
      </card>
      <card>
        <img
          slot="img"
          src="~assets/img/home/card3.png"
          width="100%"
          height="100%"
          alt=""
        />
        <div slot="title">大环境</div>
        <div slot="content">
          5G时代，加速大数据和人工智能发展，促进大健康云、远程理疗等应用落地。
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import Subtitle from 'components/content/subtitle/Subtitle'
import Card from 'components/content/card/Card'

export default {
  name: 'Reason',
  components: {
    Subtitle,
    Card
  }
}
</script>

<style scoped>
.reason {
  background-color: #fbfafa;
  padding: 50px 0;
}

.reason_content {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
</style>
