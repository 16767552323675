<template>
  <div class="tab_control_btn">
    <div class="tabs_title">
      <!--标签页标题，我们使用v-for进行渲染-->
      <div
        class="tabs_item"
        :class="{ active: item.name === current }"
        v-for="(item, index) in navList"
        :key="index"
        @click="handleChanged(index)"
      >
        {{ item.label }}
      </div>
    </div>
    <div class="tabs_content">
      <!--使用slot来进行panel嵌套-->
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TabControlBtn',
  data() {
    return {
      current: 0,
      navList: []
    }
  },
  props: {
    // value: [String, Number]
  },
  mounted() {
    // console.log(this.getTabs())
  },
  watch: {
    // value(val) {
    //   this.current = val
    // },
    current(val) {
      // 当前tab发生变化时，更新panel的显示状态
      this.updateStatus()
    }
  },
  methods: {
    // 获取panel子组件
    getTabs() {
      return this.$children.filter((item) => {
        return item.$options.name == 'TabControlPanel'
      })
    },

    // 显示对应的panel组件内容，并隐藏未被选中的panel
    updateStatus() {
      this.getTabs().forEach((panel) => {
        panel.visible = panel.name === this.current
      })
    },

    // 更新tabs
    updateNav() {
      this.navList = []

      this.getTabs().forEach((panel, index) => {
        this.navList.push({
          label: panel.label,
          name: panel.name || index
        })
        // 如果panel没有定义name属性，那么使用index作为panel标识
        if (!panel.name) {
          panel.name = index
        }
        // 设置第一个panel为当前活动panel
        if (index === 0) {
          if (!this.current) {
            this.current = panel.name || index
          }
        }
        this.updateStatus()
      })
    },

    // 点击标签页标题时触发
    handleChanged(index) {
      // 修改当前选中tab
      this.current = this.navList[index].name

      // 更新value
      // this.$emit('input', this.current)

      // 触发自定义事件，供父级使用
      // this.$emit('on-click', this.current)
    }
  }
}
</script>

<style scoped>
.tabs_title {
  display: flex;
  justify-content: center;
}

.tabs_item {
  height: 74px;
  width: 280px;
  line-height: 74px;
  font-size: 26px;
  color: #951e23;
  border-radius: 40px;
  margin: 0 10px;
  box-shadow: 3px 4px 5px 0px rgba(33, 22, 19, 0.4);
  background-color: #fff;
  cursor: pointer;
}

.active {
  background-color: #951e23;
  border: none;
  color: #fff;
}

.tabs_content {
  width: 1199px;
  height: 586px;
  margin: 50px auto 0;
  background-color: #fff;
}
</style>
